import React, { useState, useEffect } from "react";
import { VisualizeService } from '../services/user.service';
import { Card,CardContent, FormControl, InputLabel, MenuItem, Select, Typography, Grid } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { toast } from "react-toastify";
import Gauge from './Gauge';
import Battery4BarIcon from '@mui/icons-material/Battery4Bar';
import BatteryGauge from 'react-battery-gauge'
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import "react-toastify/dist/ReactToastify.css";
import Thermometer from 'react-thermometer-chart';

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    card: {
      width: '100%',
      maxWidth: '80%',
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    formControl: {
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      marginTop: theme.spacing(2),
    },
    chartCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '300px', // Fixed height for each card
      width: '100%', // Fixed width for each card
      maxWidth: '500px', // Optional: Maximum width for each card
      margin: '0 auto', // Center cards horizontally within the grid
      padding: theme.spacing(2),
    },
    chartContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%', // Ensure the chart container takes up full height of the card
    },
  }));

  const Visualize = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [visualizeContent, setVisualizeContent] = useState(null);
    const [visualizeLoading, setVisualizeLoading] = useState(true);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [deviceData, setDeviceData] = useState(null);
  
    useEffect(() => {
      if (!localStorage.getItem("token")) {
        navigate("/login");
      } else {
        VisualizeService.getVisualizeData().then(
          (response) => {
            const data = response.data.data.gauge_data;
            setVisualizeContent(data);
            setSelectedDevice(data[0].device_id);
            setDeviceData(data[0]);
            setVisualizeLoading(false);
          },
          (error) => {
            const _content = (error.response && error.response.data) || error.message || error.toString();
            setVisualizeContent(_content);
            setVisualizeLoading(false);
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_LEFT,
              autoClose: false
            });
          }
        );
      }
    }, [navigate]);
  
    useEffect(() => {
      if (visualizeContent && selectedDevice) {
        const selectedData = visualizeContent.find(d => d.device_id === selectedDevice);
        setDeviceData(selectedData);
      }
    }, [selectedDevice, visualizeContent]);
  
    const handleDeviceChange = (event) => {
      setSelectedDevice(event.target.value);
    };
  
    if (visualizeLoading) {
      return <div>Loading...</div>;
    }
  
    if (!deviceData) {
      return <div>No data available</div>;
    }
  
    return (
      <div className={classes.container}>
        <Card variant="outlined" className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="h2" className={classes.title}>
              Sensor Data / সেন্সরের তথ্য
            </Typography>
            <FormControl variant="outlined" fullWidth className={classes.formControl}>
              <InputLabel>Device ID / যন্ত্রের আইডি</InputLabel>
              <Select value={selectedDevice} onChange={handleDeviceChange} label="Device ID">
                {visualizeContent.map((device, index) => (
                  <MenuItem key={index} value={device.device_id}>
                    {device.device_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
        </Card>
  
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Air Temperature / বাতাসের তাপমাত্রা</Typography>
                <Thermometer
                    theme="light"
                    currentValue={Math.ceil(deviceData.air_temperature)}
                    max={100}
                    size="large"
                    height="200"
                    steps="5"
                    backgroundColor={"lightgray"}
                    min={0}
                    color={"red"}
                    reverseGradient={true}
                />
                 <Typography variant="subtitle2"><DeviceThermostatIcon  style={{ fontSize: 32, color: 'red' }} />Air Temperature / বাতাসের তাপমাত্রা: {parseFloat(deviceData.air_temperature)}°C</Typography>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Air Moisture / বাতাসের আর্দ্রতা</Typography>
                <Gauge value={parseFloat(deviceData.air_moisture)} max={100} label="Air Moisture" />
                <Typography variant="subtitle2">Air Moisture / বাতাসের আর্দ্রতা: {parseFloat(deviceData.air_moisture)}</Typography>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Battery Voltage / ব্যাটারির ভোল্টেজ</Typography>
                <BatteryGauge
                  animated={true}
                  value={Math.min(100,Math.max(0,(((parseFloat(deviceData.battery_voltage) - 2.4) / 1.1) * 100.0 )))}
                  maxValue={100}
                  padding={5}
                  orientation="vertical"
                  size="110"
                  label="Battery Voltage"
                />
                <Typography variant="subtitle2"><Battery4BarIcon  style={{ fontSize: 32, color: 'green' }} />Battery Voltage / ব্যাটারির ভোল্টেজ ( Less/Equal of 2.4 - 0%,Greater/Equal of 3.5 - 100%): {parseFloat(deviceData.battery_voltage)}V</Typography>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Soil Moisture 1 / মাটির আর্দ্রতা ১</Typography>
                <Gauge value={Math.max(0,Math.min(100,((1400 - parseFloat(deviceData.soil_moisture_1))/(1400-300))*100))}
                 max={100} label="Soil Moisture 1" />
                <Typography variant="subtitle2">Soil Moisture 1 / মাটির আর্দ্রতা ১ (Greater/Equal of 1400 - 0%,Less/Equal of 300 - 100%): {parseFloat(deviceData.soil_moisture_1)}</Typography>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Soil Moisture 2 / মাটির আর্দ্রতা ২</Typography>
                <Gauge value={Math.min(100,Math.max(0,((parseFloat(deviceData.soil_moisture_2) - 800)/(1000-800))*100))} max={100} label="Soil Moisture 2" />
                <Typography variant="subtitle2">Soil Moisture 2 / মাটির আর্দ্রতা ২ (Less/Equal of 800 - 0%,Greater/Equal of 1000 - 100%): {parseFloat(deviceData.soil_moisture_2)}</Typography>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Solar Radiation / সূর্যের বিকিরন</Typography>
                <Typography variant="subtitle2"><WbSunnyIcon style={{ fontSize: 128, color: '#F5CD19' }} /></Typography>
                <Typography variant="subtitle3">Solar Radiation / সূর্যের বিকিরন: {parseFloat(deviceData.solar_radiation)}</Typography>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Soil Temperature 1 / মাটির তাপমাত্রা ১</Typography>
                <Thermometer
                  currentValue={Math.ceil(deviceData.temperature_1)}
                  max="100"
                  steps="5"
                  format="°C"
                  color={"red"}
                  size="large"
                  height="200"
                />
                 <Typography variant="subtitle2"><DeviceThermostatIcon style={{ fontSize: 32, color: 'red' }} />Soil Temperature 1 / মাটির তাপমাত্রা ১: {parseFloat(deviceData.temperature_1)}°C</Typography>
              </CardContent>
            </Card>
          </Grid>
  
          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Soil Temperature 2 / মাটির তাপমাত্রা ২</Typography>
                <Thermometer
                  currentValue={Math.ceil(deviceData.temperature_2)}
                  max="100"
                  steps="5"
                  format="°C"
                  color={"red"}
                  size="large"
                  height="200"
                />
                 <Typography variant="subtitle2"><DeviceThermostatIcon style={{ fontSize: 32, color: 'red' }} />Soil Temperature 2 / মাটির তাপমাত্রা ২: {parseFloat(deviceData.temperature_2)}°C</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card variant="outlined" className={classes.chartCard}>
              <CardContent className={classes.chartContainer}>
                <Typography variant="subtitle1">Recorded On / নথিভুক্তিকরনের সময়</Typography>
                <Typography variant="subtitle2" style={{ fontSize: '20px', fontWeight: 'bold' }}> 
                  {new Date(deviceData.created_on).toLocaleString(undefined, { dateStyle: 'full', timeStyle: 'short' })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  };
  
  export default Visualize;